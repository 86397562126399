	/* ==================================================================================================================================
   * Dashboard WhatsApp WEB - Gerenciador de mensagens
   * ==================================================================================================================================
   * Copyright (C) 2010 - 2022
   * Programador: Fabio P. vilas Boas 
   *
   *  - fabio.2705@hotmail.com
   *  - multi-clinicas@hotmail.com
   *  - multiclinicas.suporte@gmail.com
   *  - Skype: Multi Clinicas
   *
   * Modulo TICKET´S
   *
   * Projeto Original
   * https://github.com/canove/whaticket
   * Coded by WhatsApp WEB
   * ----------------------------------------------------------------------------------------------------------------------------------
   * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.*/ 
	import React from "react";
	import MenuItem from "@material-ui/core/MenuItem";
	import FormControl from "@material-ui/core/FormControl";
	import Select from "@material-ui/core/Select";
	import { Checkbox, ListItemText } from "@material-ui/core";
	import { i18n } from "../../translate/i18n";

	const TicketsQueueSelect = ({
		userQueues,
		selectedQueueIds = [],
		onChange,
	}) => {
		const handleChange = e => {
			onChange(e.target.value);
		};
		return (
			<div style={{ width:120, marginTop:-4 }}>
				<FormControl fullWidth margin="dense">
					<Select multiple	displayEmpty variant="outlined" style={{ borderRadius:"8px"}}
						value={selectedQueueIds}
						onChange={handleChange}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
							getContentAnchorEl: null,
						}}
						renderValue={() => i18n.t("Consultório")}>

						{userQueues?.length > 0 &&
							userQueues.map(queue => (
								<MenuItem dense key={queue.id} value={queue.id}>
									<Checkbox
										style={{	color:queue.color, padding:"0px" }}
										size="small"
										color="primary"
										checked={selectedQueueIds.indexOf(queue.id) > -1} />
										<ListItemText primary={queue.name} style={{ padding:"0px" }} />
								</MenuItem>
							)
						)}
					</Select>
				</FormControl>
			</div>
		);
	};
	export default TicketsQueueSelect;
